.Initiative {
  max-width: 800px;
}

.Initiative-list {
  max-width: 500px;
}

.Initiative-selected {
  background-color: lightblue;
}

.Initiative-actions-large button {
  margin: 6px 10px 10px 6px;
}

.Initiative-actions-medium button {
  margin: 2px 4px 4px 2px;
}
